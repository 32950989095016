import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import TrueFalseRenderer from "../../../components/grid/TrueFalseRenderer"
import { AgGridColumn, AgGridReact } from "ag-grid-react"
import { GridReadyEvent, RowClickedEvent } from "ag-grid-community/dist/lib/events"
import { GridApi } from "ag-grid-community"
import { CustomerType } from "social-supermarket-model"

const Container = styled.div`
  .ag-row {
    cursor: pointer;
  }
`

let gridApi: GridApi = null

interface Props {
  customers: CustomerType[]
  onCustomerSelected: (customer: CustomerType) => void
}

const AdminCustomerList: FC<Props> = ({ customers, onCustomerSelected }) => {
  const handleGridReady = (gridReady: GridReadyEvent) => {
    gridApi = gridReady.api
    gridReady.api.sizeColumnsToFit()
  }

  const handleRowClicked = (event: RowClickedEvent) => {
    onCustomerSelected(event.data)
  }

  return (
    <Container className="ag-theme-balham">
      <AgGridReact
        rowData={customers}
        onGridReady={handleGridReady}
        rowSelection={"single"}
        onRowClicked={handleRowClicked}
        suppressCellSelection={true}
        domLayout={"autoHeight"}
        defaultColDef={{
          sortable: true,
          filter: true,
          resizable: true,
        }}
        frameworkComponents={{
          trueFalseRenderer: TrueFalseRenderer,
        }}
      >
        <AgGridColumn field="ID" />
        <AgGridColumn
          field="name"
          valueGetter={({ data }) => `${data.firstName} ${data.lastName}`}
        />
        <AgGridColumn field="username" />
        <AgGridColumn field="email" />
      </AgGridReact>
    </Container>
  )
}

export default AdminCustomerList
