import * as React from "react"
import { FC, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import * as orderApi from "../../../api/platform/orderApi"
import { LoginContext, Spinner } from "social-supermarket-components"
import OrderType from "../../../model/OrderType"
import OrderList from "../../orders/OrderList"
import OrderPage from "../../orders/OrderPage"
import { CustomerType } from "social-supermarket-model"

const Container = styled.div``

interface Props {
  customer: CustomerType
  orderKey: string
  onOrderSelected: (order: OrderType) => void
}

const AdminCustomerPage: FC<Props> = ({ customer, orderKey, onOrderSelected }) => {
  const { token } = useContext(LoginContext)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [orders, setOrders] = useState<OrderType[]>(null)

  useEffect(() => {
    if (!orders) {
      fetchOrders()
    }
  }, [])

  const fetchOrders = async () => {
    try {
      setIsLoading(true)
      const orders = await orderApi.getOrders(token, customer.ID)
      setOrders(orders)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelect = (order: OrderType) => {
    onOrderSelected(order)
  }

  return (
    <Container>
      <Spinner isLoading={isLoading} />
      {!orderKey && orders && orders.length > 0 && (
        <OrderList orders={orders} onSelect={handleSelect} />
      )}
      {!orderKey && orders && orders.length === 0 && (
        <div>
          No orders found for customer {customer.firstName} {customer.lastName}
        </div>
      )}
      {orderKey && <OrderPage orderKey={orderKey} />}
    </Container>
  )
}

export default AdminCustomerPage
