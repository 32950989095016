import * as React from "react"
import { FC, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import he from "he"
import {
  getVendorProducts,
  toggleVendor,
  toggleVendorHandlesStock,
  toggleVendorProduct,
} from "../../../api/platform-admin/vendorApi"
import { LoginContext, Spinner } from "social-supermarket-components"
import TrueFalseRenderer from "../../../components/grid/TrueFalseRenderer"
import { AgGridColumn, AgGridReact } from "ag-grid-react"
import { GridReadyEvent } from "ag-grid-community/dist/lib/events"
import { GridApi, RowNode } from "ag-grid-community"
import ImageRenderer from "../../../components/grid/ImageRenderer"
import Toggle from "../../../components/toggle/Toggle"
import { getStockQuantityFromProduct } from "../../../util/productUtil"
import { isVisibleOnGiftingPlatform, showInGiftingCatalogue } from "../../../util/productFilterUtil"
import {
  ProductType,
  VendorType,
  getFormattedPriceRange,
} from "social-supermarket-model"

const Container = styled.div`
  width: 100%;

  .ag-row {
    cursor: auto;
  }
`
const ToggleContainer = styled.div`
  position: relative;
`
const TopBar = styled.div`
  background-color: white;
  padding: 20px;
`
const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h1 {
    font-size: 2em;
    margin-right: 10px;
    margin-bottom: 0;
  }
`

const Products = styled.div`
  margin-top: 10px;
`
const ManagesStock = styled.div`
  margin-top: 15px;
  font-size: 1.3em;
  position: relative;
  display: flex;

  p {
    margin-right: 10px;
    margin-bottom: 0;
  }
`

interface Props {
  vendor: VendorType
  replaceVendor: (vendor: VendorType) => void
}

let gridApi: GridApi = null

const VendorAdminPage: FC<Props> = ({ vendor, replaceVendor }) => {
  const { token } = useContext(LoginContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [updatingVendorStock, setIsUpdatingVendorStock] = useState<boolean>(false)
  const [products, setProducts] = useState<ProductType[]>(null)

  useEffect(() => {
    if (vendor && !products) {
      init()
    }
  }, [vendor])

  const init = async () => {
    try {
      setIsLoading(true)
      setProducts((await getVendorProducts(token, vendor.ID)).filter(product => !product.isByo))
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleGridReady = (gridReady: GridReadyEvent) => {
    gridApi = gridReady.api
    gridReady.api.sizeColumnsToFit()
  }

  const handleToggleVendor = async () => {
    try {
      setIsLoading(true)
      await toggleVendor(token, vendor.ID, !vendor.isPlatformEnabled)
      replaceVendor({ ...vendor, isPlatformEnabled: !vendor.isPlatformEnabled })
      gridApi.refreshCells()
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const toggleHandlesStock = async () => {
    try {
      setIsUpdatingVendorStock(true)
      await toggleVendorHandlesStock(token, vendor.ID, !vendor.platformHandlesStock)
      replaceVendor({ ...vendor, platformHandlesStock: !vendor.platformHandlesStock })
    } catch (e) {
      console.log(e)
    } finally {
      setIsUpdatingVendorStock(false)
    }
  }

  const handleToggleProduct = async (oldValue, productId, node: RowNode) => {
    node.setDataValue("onPlatform", "isLoading")
    const product = products.find(product => product.productId === productId)
    const newValue = !oldValue
    try {
      const newProduct = await toggleVendorProduct(token, productId, newValue)
      const index = products.findIndex(product => product.productId === productId)
      products[index] = { ...newProduct }
      setProducts([...products])
      node.setDataValue("onPlatform", newValue)
    } catch (e) {
      node.setDataValue("onPlatform", oldValue)
      console.log(e)
    }
  }

  const ToggleRenderer = props => {
    const { data, value } = props
    return (
      <ToggleContainer>
        <Spinner isLoading={value === "isLoading"} isLocal />
        {value !== "isLoading" && (
          <Toggle
            enabled={value}
            onChange={() => handleToggleProduct(value, data.productId, props.node)}
          />
        )}
      </ToggleContainer>
    )
  }

  return (
    <Container>
      <Spinner isLoading={isLoading} />
      <TopBar>
        <Title>
          <h1>{he.decode(vendor.name)}</h1>
          <Toggle enabled={vendor.isPlatformEnabled} onChange={handleToggleVendor} />
        </Title>
        {vendor.isPlatformEnabled && (
          <ManagesStock>
            <Spinner isLoading={updatingVendorStock} isLocal />{" "}
            <p>Remove products when out of stock </p>
            <Toggle enabled={vendor.platformHandlesStock} onChange={toggleHandlesStock} />
          </ManagesStock>
        )}
      </TopBar>
      {products && vendor.isPlatformEnabled && (
        <Products>
          <AgGridReact
            rowData={products}
            onGridReady={handleGridReady}
            suppressCellSelection={true}
            domLayout={"autoHeight"}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
            }}
            frameworkComponents={{
              trueFalseRenderer: TrueFalseRenderer,
              imageRenderer: ImageRenderer,
              toggleRenderer: ToggleRenderer,
            }}
          >
            <AgGridColumn
              field="image"
              cellRenderer="imageRenderer"
              width={50}
              suppressSizeToFit={false}
              headerName={""}
            />
            <AgGridColumn field="name" valueFormatter={e => he.decode(e.value)} />
            <AgGridColumn field="price" valueFormatter={e => getFormattedPriceRange(e.data)} />
            <AgGridColumn field="stockStatus" />
            <AgGridColumn
              field="stockQuantity"
              valueGetter={({ data }) => getStockQuantityFromProduct(data)}
            />
            <AgGridColumn
              field="onPlatform"
              valueGetter={({ data }) => isVisibleOnGiftingPlatform(data)}
              cellRenderer="toggleRenderer"
            />
            <AgGridColumn
              field="visibleOnCatalogue"
              valueGetter={({ data }) => showInGiftingCatalogue(data)}
              cellRenderer="trueFalseRenderer"
              headerName={"Visible in Catalogue"}
            />
          </AgGridReact>
        </Products>
      )}
    </Container>
  )
}

export default VendorAdminPage
