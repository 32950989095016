import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import GLink from "../../../gatsby/GLink"
import { isRunTime } from "../../util/generalUtil"
import { IconWrapper } from "social-supermarket-components"
import { colors } from "social-supermarket-model"

const Container = styled.div`
  a {
    display: block;
    width: 100%;
    padding: 15px;
    color: black;
    font-size: 0.9em;

    background-color: ${({ selected }) =>
      selected ? colors.sidebarSelectedGrey : colors.sidebarGrey};

    &:hover {
      background-color: ${colors.sidebarHoverGrey};
      text-decoration: none;
    }

    i {
      width: 40px;
    }
  }

  button {
    display: block;
    width: 100%;
    padding: 15px;
    color: black;
    font-size: 0.9em;
    font-weight: bold;
    border: none;
    text-align: left;
    cursor: pointer;
    background-color: ${({ selected }) => (selected ? colors.lightGray : "white")};

    &:hover {
      background-color: ${colors.grey};
      text-decoration: none;
    }

    i {
      width: 40px;
    }
  }
`

interface Props {
  to?: string
  onClick?: () => void
  selected?: boolean
  label: string
  icon: string
}

const SideBarItem: FC<Props> = ({ to, selected, onClick, label, icon }) => {
  return (
    <Container selected={selected || (isRunTime() && window.location.pathname === to)}>
      {onClick && (
        <button onClick={onClick}>
          {" "}
          <IconWrapper name={icon} />
          {label}
        </button>
      )}
      {to && (
        <GLink to={to}>
          {" "}
          <IconWrapper name={icon} />
          {label}
        </GLink>
      )}
    </Container>
  )
}

export default SideBarItem
