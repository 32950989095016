import * as React from "react"
import { FC, useContext } from "react"
import styled from "styled-components"
import AdminPage from "../social-supermarket/pages/admin/AdminPage"

import { navigate } from "gatsby"
import { LoginContext } from "social-supermarket-components"

const Container = styled.div``

const SidebarContainer = styled.div`
  width: 200px;
  background-color: white;

  display: flex;
  flex-direction: column;
  padding: 10px 0;
`
const BottomContainer = styled.div`
  display: flex;
  height: calc(100vh - 50px);
  width: 100vw;
`

interface Props {}

const Admin: FC<Props> = () => {
  const { user } = useContext(LoginContext)
  if (user && !user.roles.includes("administrator")) {
    navigate("/")
  }
  return user && user.roles.includes("administrator") ? <AdminPage /> : null
}

export default Admin
