import * as React from "react"
import { FC, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { LoginContext, Spinner, Tab, Tabs } from "social-supermarket-components"
import { getBacsPayments, getPendingPayments } from "../../../api/platform-admin/paymentsApi"
import AdminPendingPaymentsList from "./AdminPendingPaymentsList"
import LinkButton from "../../../components/button/LinkButton"
import AdminPaymentPage from "./AdminPaymentPage"
import AdminAllPaymentsList from "./AdminAllPaymentsList"
import { PaymentType } from "social-supermarket-model"

const Container = styled.div`
  margin: 10px;
`

const BackButton = styled(LinkButton)`
  margin-bottom: 10px;
  margin-top: 10px;
`

const StyledTabs = styled(Tabs)`
  background-color: white;
  margin-left: 5px;
  padding: 15px;
  margin-bottom: 5px;
  margin-right: 5px;
`

interface Props {}

const AdminPaymentsPage: FC<Props> = () => {
  const { token } = useContext(LoginContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [payments, setPayments] = useState<PaymentType[]>([])
  const [pendingPayments, setPendingPayments] = useState<PaymentType[]>([])
  const [selectedPayment, setSelectedPayment] = useState<PaymentType>(null)
  const [currentTab, setCurrentTab] = useState<number>(0)

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    try {
      setIsLoading(true)
      setPayments(await getBacsPayments(token))
      setPendingPayments(await getPendingPayments(token))
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleApprove = () => {
    setIsLoading(true)
    setSelectedPayment(null)
    init()
  }

  const handleBack = () => {
    setSelectedPayment(null)
  }

  const handlePaymentSelected = (payment: PaymentType) => {
    setSelectedPayment(payment)
  }

  return (
    <Container>
      <Spinner isLoading={isLoading} />
      {selectedPayment && <BackButton onClick={handleBack}>{"< Back"}</BackButton>}
      {!selectedPayment && (
        <StyledTabs currentTab={currentTab} setCurrentTab={i => setCurrentTab(i)}>
          <Tab title={`Payments to Invoice (${payments.length})`} id="all-payments">
            <AdminAllPaymentsList payments={payments} />
          </Tab>

          {payments && !selectedPayment && (
            <Tab title={`Pending Approval (${pendingPayments.length})`} id="pending-approval">
              <AdminPendingPaymentsList
                payments={pendingPayments}
                onPaymentSelected={handlePaymentSelected}
              />
            </Tab>
          )}
        </StyledTabs>
      )}
      {selectedPayment && <AdminPaymentPage payment={selectedPayment} onApprove={handleApprove} />}
    </Container>
  )
}

export default AdminPaymentsPage
